import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"
import Layout from "../components/layout"
// import SEO from "../components/seo"
import "../assets/sass/landscaping.sass"
import ContactForm from '../components/contactForm'

import Carousel from 'react-bootstrap/Carousel'
import 'bootstrap/dist/css/bootstrap.min.css'

const imageTwo = "https://firebasestorage.googleapis.com/v0/b/kevins-fencing.appspot.com/o/PicsArt_06-22-09.07.36.jpg?alt=media&token=fbfbb35d-d82e-41a2-b54b-0f77614ba866"
const imageThree = "https://firebasestorage.googleapis.com/v0/b/kevins-fencing.appspot.com/o/PicsArt_06-22-09.15.49.jpg?alt=media&token=deb8db8b-77a4-4399-b4ca-c61b4e2cfe94"


const Pavers = () => (
<div className="info-page pavers">

    <div className="info-page-intro">
        <h1>Pavers</h1>
        <p>Helping you keep your property healthy, clean, and safe.</p>
    </div>
    <Carousel>
        <Carousel.Item interval={2500}>
            <img src={imageTwo}/>
        </Carousel.Item>
        <Carousel.Item interval={2500}>
            <img src={imageThree}/>
        </Carousel.Item>
    </Carousel>

    <div className="discription">

        <p>
            At Kevins Fencing and landscaping, we’ll do whatever is needed to get your property ready for the next season.
            We come with years of experience and a reputation of doing exceptional jobs. 
            Getting your landscape back into shape after the end of the season can be a big job, and we have the team to tackle just that.
        </p>

    </div>

    <ContactForm/>

</div>

)

export default Pavers